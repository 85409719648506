header {
  background-color: $header-bg;
  color: $header-color;
  height: 5.8em;

  a, a:hover, a:visited {
    color: $header-color;
    text-decoration: none;
  }

  a:hover {
    border-bottom: 1px solid $header-color;
  }
}

.header-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.4em;
}

.header-logo {
  @extend %header;
  height: 2em;
  width: 1em;
}

.env-branding {
  font-weight: bold;
  float: left;
  font-size: 2.5rem;
  color:white;
  padding: 1rem;
  margin-left: 75px;
  color: $brand-warning;
}
