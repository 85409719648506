.pull-right {
  text-align: right;
}

.wrap-image-left {
  float:left;
  padding-right:0.5em;
}

ul.button-list {
  line-height:3em;
  list-style-type: none;
}

%header {
    background: {
        image: url("data:image/svg+xml,%3Csvg id='Layer_2' data-name='Layer 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23381730%3B %7D.st0 %7B fill: %23FFFFFF%3B %7D %3C/style%3E%3C/defs%3E%3Crect class='st0' width='80' height='80'/%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath class='cls-1' d='M80 0H0V80H80V0ZM38.8 18.62c.5-.84 1.71-.84 2.21-.01l9.94 16.39H29.08l9.72-16.38Zm-30.44 14.44l8.56-14.44c.5-.84 1.71-.84 2.21-.01l9.94 16.39H9.46c-1 0-1.62-1.09-1.11-1.95Zm63.29 28.97c-7.91 0-7.91-2.94-15.82-2.94s-7.91 2.94-15.82 2.94-7.91-2.94-15.82-2.94-7.91 2.94-15.82 2.94v-5.79c7.91 0 7.91-2.94 15.82-2.94s7.91 2.94 15.82 2.94 7.91-2.94 15.82-2.94 7.91 2.94 15.82 2.94v5.79Zm0-12.07c-7.91 0-7.91-2.94-15.82-2.94s-7.91 2.94-15.81 2.94-7.91-2.94-15.82-2.94-7.91 2.94-15.81 2.94v-5.79c7.91 0 7.91-2.94 15.81-2.94s7.91 2.94 15.82 2.94 7.91-2.94 15.81-2.94 7.91 2.94 15.82 2.94v5.79Zm-1.11-14.95h-19.59l9.72-16.38c.5-.84 1.71-.84 2.21-.01l8.76 14.44c.52 .86-.1 1.96-1.1 1.96Z'/%3E%3C/g%3E%3C/svg%3E");
        repeat: no-repeat;
    }
    padding-left: 1.7em;
}

@mixin asterisk {
  &:after {
    content: "*";
    color: red;
  }
}
