$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
$font-family-base: 'Source Sans Pro', $font-family-sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.5;

$active-blue: #0072DA;
$active-blue-focus: #0E5CBA;

$link-colour: $active-blue;

$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

$transparent: rgba(0, 0, 0, 0);

$brand-white: white;
$brand-grey: #373636;

$brand-success: #36B37E;
$brand-info: $active-blue;
$brand-warning: #FFAB00;
$brand-danger: #CC3931;

$header-color: white;
$header-bg: $brand-complementary1;

$body-color: $gray-dark;
$body-bg: $brand-complementary3;

$footer-color: white;
$footer-bg: $brand-grey;

$search-even: #f5f5f5; // whitesmoke

$btn-primary-bg: $active-blue;
$btn-primary-text-color: $brand-white;
$btn-primary-bg-hover: $active-blue-focus;

$btn-secondary-bg: $btn-primary-text-color;
$btn-secondary-text-color: $btn-primary-bg;
$btn-secondary-bg-hover: $btn-primary-bg;

$btn-danger-bg: $brand-danger;
$btn-danger-text-color: $brand-white;
$btn-danger-bg-hover: darken($btn-danger-bg, 10%);

$btn-disabled-bg: #888;
$btn-disabled-text-color: $brand-white;
$btn-disabled-bg-hover: #666;

