.machine-account-table {
	border-collapse: collapse;
	width: 100%;
    margin-bottom: 10px;

    input[type=text] {
        margin-bottom: 0;
    }

    td {
        padding:10px;
    }
}

.machine-account-table-header {
	text-align:left;
	background:none repeat scroll 0 0;
	border-bottom:1px solid #BBBBBB;
	padding:10px;
	width:70px;
}

.machine-account-table-narrow-col {
	width: 5%;
}

.machine-account-table-wide-col {
	width: 20%;
}

.machine-account-table-col {
	width: 10%;
}
