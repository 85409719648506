.search {
  flex: 0 0 19%;
  max-width: 19%; // stops content forcing it wider
}

p.no-spacing {
  margin: 0;
}

.search .btn {
  width: 100%;
  margin-bottom: 0.5rem;
}

.search-wrapper {
  display: flex;
  justify-content: space-between;
}

.dataTables_info {
  padding-left: 10px;
}

.search-results {

  flex: 0 0 79%;
  overflow-x: auto;
  margin-bottom: 3rem;
}
