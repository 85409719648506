 .details-comparison-result{

  flex: 0 0 60%;
  overflow-x: auto;
  margin-bottom: 3rem;

  table {
    min-width: 40%;

    .no-footer {
      border-bottom: 1px solid $gray;
    }

    table-layout: fixed;

    thead th {
      text-align: left;
    }

    td {
      // 'overflow-wrap' is the official name, 'word-wrap' is an alias. IE only recognises 'word-wrap'
      word-wrap: break-word;
    }

    thead th, thead td {
      padding-left:10px;
      border-bottom: 1px solid $gray;
    }

    tbody td {
      padding-left:10px;
    }

  }
}