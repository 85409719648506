strong + a.btn-machineadminsearch {
  margin-left: 15px;
  padding: 8px 20px;
}

.searchdialog-primefaces-override {
  // primefaces does not allow percentages in its size properties so we overwrite dialog size here
  width: 80% !important;

}

.searchdialog-selectadmin {
  color: $link-colour !important;
  &:visited {
    color: darken($link-colour, 20%) !important;
  }

  &:hover {
    color: lighten($link-colour, 20%) !important;
  }
}


#machineAdminInitiateSearch{
  strong + input {
  margin-left: 2em;
}
}

