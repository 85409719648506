html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  background-color: $body-bg;
  background-image: linear-gradient(135deg, $brand-primary, $brand-complementary2);
  background-attachment: fixed;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  main {
      background: white;
  }

  @media (min-width: 950px) {
    flex-direction: row;
  }
}

.wrapper, footer nav {
  margin-right: 0.5rem;

  @media (min-width: 1300px) {
    margin-right: 10rem;
  }
}

header {
  h1 {
    margin-left: 1rem;
    line-height: 1;
    margin-top: 0.2em;
  }
}

.sidebar {
  @media (min-width: 950px) {
    flex: 0 0 15.5rem;
  }
}

.content {
  flex: 1 1 auto;
  border: {
    right: 1px solid $brand-accent;
    left: 1rem solid white;
  }
  padding: {
    right: calc(0.625em + 2px);
  }

  @media (min-width: 950px) {
      flex-basis: calc(100% - 16.5rem);
  }
}
