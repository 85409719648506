body {
  //// Go easy on the eyes and use something other than `#000` for text
  color: $body-color;
  //// By default, `<body>` has no `background-color` so we set one as a best practice.
  background-color: $body-bg;
  min-height: 100%;
}

button,
a.button,
input[type="reset"],
input[type="submit"],
input[type="button"],
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.logged-in {
  margin-top: 1rem;
  form {
    padding-left:0.2rem;
  }
}

a {
  color: $link-colour;
  text-decoration: none;

  &:visited {
    color: $link-colour;
  }

  &:hover {
    border-bottom: 1px solid $link-colour;
  }
}
