.role-requires-approval  {
  color: $brand-danger;
  font-weight: bold;
}

.role-is-approved {
  font-weight: bold;
}

.role-pending-approval {
  font-weight: bold;
  color: $brand-danger;
}

label {
  .role-requires-approval, .role-is-approved {
    margin-left: 2em;
  }
}


div.ui-accordion-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.role-group-num-pending {
  font-style: italic;
  font-size: 0.8em;
  margin-right: 3em;
}

#approveRoles {
    margin-bottom: 3rem; 
}
