@media only screen and (max-width: 914px) {
     html {
         max-width: 100%;
    }
     body > header {
         max-width: 100%;
    }
     body > footer {
         max-width: 100%;
    }
     fieldset {
         overflow: hidden;
         display: contents;
         a {
             white-space: nowrap;
             max-width: 90% !important;
             overflow: hidden;
             text-overflow: ellipsis !important;
             display: block;
        }
    }
     .wrapper {
         width: 100%;
         margin-top: 0em;
    }
     .content {
         overflow-x: clip;
         .login-container {
            padding-top: 0rem !important;
            margin: 0 96% 2em 100% !important;
            max-width: 100% !important;
         }
         .login-local {
             header {
                 position: unset !important;
                 width: unset !important;
            }
             max-width: 100% !important;
             margin: 0 !important;
        }
    }
     #choosePasswordForm {
         margin-bottom: 1em;
    }
}
