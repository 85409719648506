a.forgotten-link {
  float: right;
  line-height: normal;
}

.login {
  margin: 0;
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;

  .env-branding {
    margin-left: 0;
  }

  .login-container {
    margin: 0;
    display: flex;
    border: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 8rem;
  }
 
  #loginForm-messages {
    li span {
      margin:0;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 10px 0 10px;
    justify-content: space-around;
  }

  header {
    padding: 0.35em calc(0.625em + 2px) 0.75em;
    h2 {
      @extend %header;
      margin: 0;
    }
    height: auto;
  }

  footer {
    padding: 0.3em;
    background: $brand-complementary1;
    margin: 0;
    font-size: 90%;
 	  text-align: center;

     a {
      color: $brand-white;
      text-decoration: none;
    
      &:visited {
        color: $brand-white;
      }
    
      &:hover {
        border-bottom: 1px solid $brand-white;
      }
    }
  }

  .formButtonGroup input[type=submit] {
    width: 100%;
  }

  .login-local {
    width: 360px;
    box-shadow: 0 0 5px $brand-accent;
    display: flex;
    flex-flow: column nowrap;

    form {
      background: white;
      flex: 1 0 auto;
      display: flex;
      flex-flow: column nowrap;

      .create-local-inner {
        flex: 1 0 auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        margin: 0 2px;
        padding: 0.35em 0.625em 0.75em;

        .formButtonGroup {
          flex: 1 0 auto;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;
        }
      }
    }

    .btn {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
    }
  }

  .create-local {
    margin-right: 6rem;
  }

  .login-alternate {
    margin-bottom: 0.5rem;
    margin-top: auto;
    width: auto;
    float: left;
    display: flex;
    flex-direction: column;
    background: transparent;
    box-shadow: 0 0 0;

    fieldset {
      float: left
    }
  }

}

.office365, .office365:hover, .office365:disabled {
  background-color: white;
  background-image: url("\#{resource['office365:icon_office_med.jpg']}");
  background-size: contain;
  background-repeat: no-repeat;
  border: #e94124 solid 1px;
  border-radius: 2px;
  color: #e94124;
  font-size: 13px;
  margin-left: 3px;
  box-sizing: border-box;

  span {
    display: inline-block;
    padding: 5px 1px 5px 37px;
  }
}

.google, .google:hover, .google:disabled {
  background-color: transparent;
  background-image: url("\#{resource['google:btn_google_signin_dark_normal_web.png']}");
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  margin-left: 0;
  width: 164px;
  height: 42px;
  margin-top: -3px;

  span {
    display: inline-block;
    padding: 5px 1px 5px 37px;
  }
}

.desaturate {
  -webkit-filter: grayscale(75%);
  filter: grayscale(75%) opacity(75%);
  opacity: 0.75;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

.context_btn_select {
  white-space: normal;
}

.eye_on {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='14' viewBox='0 0 21 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 3.3125V3.34766C12.6797 3.34766 14.4375 5.10547 14.4375 7.25C14.4375 9.42969 12.6797 11.1875 10.5 11.1875C8.32031 11.1875 6.5625 9.42969 6.5625 7.25C6.5625 6.86328 6.63281 6.51172 6.73828 6.16016C6.98438 6.33594 7.33594 6.40625 7.6875 6.40625C8.74219 6.40625 9.65625 5.52734 9.65625 4.4375C9.62109 4.12109 9.55078 3.76953 9.375 3.48828C9.72656 3.38281 10.1133 3.34766 10.5 3.3125ZM20.4844 6.75781C20.5547 6.89844 20.5898 7.07422 20.5898 7.28516C20.5898 7.46094 20.5547 7.63672 20.4844 7.77734C18.5859 11.5039 14.7891 14 10.5 14C6.17578 14 2.37891 11.5039 0.480469 7.77734C0.410156 7.63672 0.375 7.46094 0.375 7.25C0.375 7.07422 0.410156 6.89844 0.480469 6.75781C2.37891 3.03125 6.17578 0.5 10.5 0.5C14.7891 0.5 18.5859 3.03125 20.4844 6.75781ZM10.5 12.3125C13.9453 12.3125 17.1445 10.3789 18.832 7.25C17.1445 4.12109 13.9453 2.1875 10.5 2.1875C7.01953 2.1875 3.82031 4.12109 2.13281 7.25C3.82031 10.3789 7.01953 12.3125 10.5 12.3125Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 98%;
}

.eye_off {
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='19' viewBox='0 0 23 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.5391 16.8086C22.6445 16.9141 22.75 17.0898 22.75 17.2656C22.75 17.4062 22.6797 17.5117 22.6094 17.6172L22.2578 18.0391C22.1523 18.1797 22.0117 18.25 21.8008 18.25C21.6953 18.25 21.5547 18.2148 21.4844 18.1445L0.460938 1.69141C0.320312 1.62109 0.214844 1.44531 0.214844 1.26953C0.214844 1.12891 0.285156 1.02344 0.355469 0.917969L0.707031 0.460938C0.8125 0.355469 0.953125 0.285156 1.16406 0.285156C1.26953 0.285156 1.41016 0.320312 1.51562 0.390625L22.5391 16.8086ZM10.6562 5.41797C10.9375 5.34766 11.2188 5.34766 11.5 5.3125C13.6094 5.3125 15.332 7 15.4023 9.10938L10.6562 5.41797ZM12.3086 13.1172C12.0273 13.1875 11.7461 13.1875 11.5 13.1875C9.35547 13.1875 7.63281 11.5352 7.5625 9.42578L12.3086 13.1172ZM11.5 4.1875C10.7969 4.1875 10.0938 4.29297 9.42578 4.46875L7.80859 3.16797C8.96875 2.74609 10.1992 2.5 11.5 2.5C15.7891 2.5 19.5859 5.03125 21.4844 8.75781C21.5547 8.89844 21.625 9.07422 21.625 9.28516C21.625 9.46094 21.5547 9.63672 21.4844 9.77734C20.9922 10.7617 20.3594 11.6406 19.6211 12.4141L18.2852 11.3945C18.918 10.7617 19.4102 10.0586 19.832 9.25C18.1445 6.12109 14.9453 4.1875 11.5 4.1875ZM11.5 14.3125C12.168 14.3125 12.8711 14.2422 13.5391 14.0664L15.1562 15.3672C13.9961 15.7539 12.7656 16 11.5 16C7.17578 16 3.37891 13.5039 1.48047 9.77734C1.41016 9.63672 1.375 9.46094 1.375 9.25C1.375 9.07422 1.41016 8.89844 1.48047 8.75781C1.97266 7.77344 2.60547 6.89453 3.34375 6.12109L4.67969 7.14062C4.04688 7.77344 3.55469 8.47656 3.13281 9.25C4.82031 12.3789 8.01953 14.3125 11.5 14.3125Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 98%;
  cursor: pointer;
}

::-ms-reveal {
    display: none;
}