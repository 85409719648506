@mixin wfiam-input {
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  border: 1px solid $active-blue {
    radius: 5px;
  }
  margin-bottom: 10px;
  font-size: 14px;
  outline: none;

  &:focus {
    box-shadow: 0 0 10px $active-blue;
  }

  &.error {
    outline: none;
    border: 1px solid red;
  }

  &[readonly] {
    background-color: $gray-lighter;
  }
}

select[size],
select {
  @include wfiam-input;
  padding-left: 1rem;
}

.ui-selectonemenu, // primefaces select element
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  @include wfiam-input;
  padding: 0 20px 0 1rem;
}

.decline-reason-select {
  margin-bottom: 0;
}

fieldset {
  border: 0;
}

dl {
  margin: {
    top: 0;
    bottom: 1rem;
  }
}

.details-list {

  dd {
    display: inline-block;

  }

  dt {
    vertical-align: top;
  }

  ul {
    margin: 0;
    padding:0;
    list-style: none;
  }

}

dt {
  display: inline-block;
  font-weight: 700;
  min-width: 11.6rem;
  text-align: right;
  margin-right: 0.75rem;
}

dd {
  display: inline;
  margin-left: 0;
  margin-bottom: 0.2rem;
}

dd:after {
  display: block;
  content: '';
}

hr {
  height: 1px;
  border: none;
}

.complex-field {
  margin-bottom: 20px;

  input {
    margin-bottom: 3px;
  }
}

.form-field-checkbox {

  padding: {
    top: 0.5rem;
    bottom: 0.5rem;
  }

  label {
    padding-left: 0.5rem;
  }

}

.required-field {
  @include asterisk;
}

.item-state-disabled:after {
  content: "[this application role is currently disabled]";
  color: #888;
  padding: 0.3em;
}

.item-state-sda-provisioned:after {
  content: "[SDA-provisioned only]";
  color: #888;
  padding: 0.3em;
}

.item-state-disabled.item-state-sda-provisioned:after {
  content: "[this application role is currently disabled] [SDA-provisioned only]";
}

.item-state-sda-edu-provisioned:after {
  content: "[Education Service Desk provisioned only]";
  color: red;
  padding: 0.3em;
}

.item-state-disabled.item-state-sda-edu-provisioned:after {
  content: "[this application role is currently disabled] [Education Service Desk provisioned only]";
  color: #888;
}

.item-state-sda-edu-provisioned-with-link {
  color: red;
  font-weight: bold;
}

.complete-form-icon {
  padding-left: 3px;
  vertical-align: middle;
}

.formButtonGroup {
  line-height: 3.2;
}

.capslock-warning {
  position: relative;
  padding: 5px;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  z-index: 99;
  width:9.8125rem;

  p {
    margin: 0
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 20%;
    height: 10px;
    width: 10px;
    background: white;
    transform: rotate(45deg) translate(-80%);
    border: {
      top: inherit;
      left: inherit;
    }
  }
}


td.role-name {
  width: 25%;
}

.role-select__checkbox {
  display: inline-block;
  vertical-align: top;
  margin-top: 0.25em;
}

.role-select__radio {
  display: inline-block;
  vertical-align: top;
  margin-top: 0.1em;
  margin-left: 1.5em;
}

.role-select__label {
  display: inline-block;
  width: 95%;
}

.radio-role-details {
	margin-left: 3em;
	margin-bottom: 1em;
}

.role-select-radio__label {
  display: inline-block;
  width: 95%;
  padding-bottom: 0.5em;
  padding-top: 0.15em;
}

.role-select__name {
  font-weight: bold;
}

.radio-role-gap {
  margin-left: 0.4em;
}

.role-checked {
    background-color: red;
}

.role-select__description {
  display: inline-block;
  margin-left: 1rem;
}

.wfiam-select-warning-label {
  display: block;
  font-size: 90%;
  padding-left:1rem;
}

.warningLabel {
  font-weight: bold;
}

.labelCssLineHeight {
  line-height:1.5;
}

.bigIcon {
  font-size: 2rem;
  margin: 1rem 1rem 0 0;
  float: left;
}

.reject_buttons {
  width : 47%;
}

.error-message-page-top {
	
	.ui-messages-error-icon {
		position: static; 
		display: inline; 
			
	}
	
	border: solid 1px $brand-danger; 
	border-radius: 1em;
	padding: 1em;
	color: $brand-danger; 
	font-weight: bold; 
	vertical-align: baseline; 
	
	}
	
.clear-role-selection {
	margin-left:1.0em; 
	font-size:0.9em;
	color:#0072DA	;
	font-weight: normal; 
	cursor:pointer;
}
	
.clear-role-selection:hover {
	text-decoration: underline;
}