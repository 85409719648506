
table.dataTable {
  .no-footer {
    border-bottom: 1px solid $gray;
  }

  table-layout: fixed;

  td {
    // 'overflow-wrap' is the official name, 'word-wrap' is an alias. IE only recognises 'word-wrap'
    word-wrap: break-word;
  }

  thead th, thead td {
    border-bottom: 1px solid $gray;
    text-align: left;
    overflow-x: hidden;

    &.dob, &.date {
      width: 5rem;
    }

    &.checkbox {
      width: 2rem;
    }

    &.approveDecline {
      width: 14rem;
    }

    &.status {
      width: 4rem;
    }

  }

  tfoot tr td {
    padding-left: 0;
  }

  .approvaldate {
    width: 7rem;
  }

}

.narrow {
  width: 10em;
}

.dataTables_filter label {
  font-weight: bold;
}

.dataTables_filter label input{
  font-weight: normal;
}

tr.even {
  background-color: $search-even !important;
}

tr.even {
  td.approveDecline {
    table tbody tr {
      background-color: $search-even !important;
    }
  }
}

