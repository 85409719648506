.downloadAudio {
  display:none
}
.embeddedAudio {
  display:block
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
  .downloadAudio {
    display:inline
  }
  .embeddedAudio {
    display:none
  }
}
@supports(overflow:-webkit-marquee) and (justify-content:inherit) {
  .downloadAudio {
    display:inline
  }
  .embeddedAudio {
    display:none
  }
}
@media only screen and (max-width:51.99em) {
  .downloadAudio {
    display:inline
  }
  .embeddedAudio {
    display:none
  }
}

.audioMessage {
  padding-left:1rem;
}