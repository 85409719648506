.org-details {
  display: flex;
  flex-direction: column;

  @media (min-width: 1100px) {
    flex-direction: row;
  }

  .org-roles {
    @media (min-width: 1100px) {
      flex: 0 0 50%;
    }

    @media (min-width: 1500px) {
      flex: 0 0 33%;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (min-width: 1100px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    h4 {
      flex: 0 0 100%;

      @media (min-width: 1100px) {
        flex: 0 0 auto;
      }
    }

    .org-roles-section {
      flex: 0 0 50%;

      &:nth-last-child(1) {
        flex: 0 0 100%;
      }

      @media (min-width: 1100px) {
        flex: 0 0 auto;
        &:nth-last-child(1) {
          flex: 0 0 auto;
        }
      }
    }

    .ui-autocomplete-multiple-container.ui-inputfield {
    	width: 95%;
    }
  }

  .org-select-roles-section {
    @media (min-width: 1100px) {
      flex: 0 0 50%;
    }

    @media (min-width: 1500px) {
      flex: 0 0 66%;
    }

    table {
      width: 100%;
    }

    td, th {
      padding: 0.5rem;
    }

    th {
      text-align: left;
    }

    td {
      border-top: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      vertical-align: top;
    }
  }

  .addedAdditionalRole {
    font-weight: bold;
  }
}

div.ui-accordion-header.application-has-roles {
    background: $btn-primary-bg;
    color: $btn-primary-text-color;

    &:hover {
        background: $btn-primary-bg-hover;
    }
}
