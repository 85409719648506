/*
 IE10+ can't be targeted by conditional statements in html, the current recommended approach to deal with IE
 is media queries that only IE implements.
 https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/
*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    height: 100%;
    // overflow-y: hidden;
  }

  .content {
      //removing because of content element overlap on small screens
      //height: 100%;
    @media (min-width: 950px) {
      flex-basis: auto;
    }
  }

  .login-local {
    min-height: auto;
  }

  .login-local.create-local {
    min-height: 410px;
  }

}
