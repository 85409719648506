.main-nav {
  background-color: rgba(0, 0, 0, 0);
  border: {
    right: 1px solid;
  }

  &__nav-items {
      display: block;
      box-sizing: border-box;
      list-style: none outside none;
      padding: {
          left: 0rem;
          right: 0rem;
      }
      margin-top: 0.5rem;
  }
}

@mixin nav-item {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 2.2rem;
    font-size: 1.3rem;
    padding: {
        left: 0.5rem;
    }
    margin-bottom: 0.5rem;
    color: $brand-white;
}

.nav-item {
    @include nav-item;
}

.nav-item-bold {
    @include nav-item;
    font-weight: bold;
}

.nav-item__link {
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 1.2rem;
    position: relative;
    color: $body-color;
    background-color: white;
    border: 0.1rem {
        style: solid;
        color: $brand-complementary1;
        right: 0;
    }
    padding: {
        left: 0.5rem;
        right: 15px;
    }
    &:visited {
        color: $body-color;
    }

    &_active {
        &::after {
            content: '\f054';
            font-family: 'FontAwesome', serif;
            position: absolute;
            top: calc(50% - 1rem);
            right: 0.2rem;
            font-size: 1rem;
            line-height: 2;
            box-sizing: border-box;
            border-collapse: separate;
        }

        &:hover {
            background-color: $active-blue-focus;
            color: $brand-white;
        }
    }

    &_selected {
        background-color: $active-blue;
        color: $brand-white;

        &:visited {
            color: $brand-white;
        }
    }

    &_inactive {
        color: LightGrey;
    }
}

.nav-item__link_external {
    &_active {
        &::after {
            content: '\f08E';
        }
    }

}