button.btn,
a.btn,
input[type="reset"].btn,
input[type="submit"].btn,
input[type="button"].btn {
    // Not strictly BEM, but need the specificity to beat formalize
    padding: 10px 29px;
    border: 1px solid $btn-primary-bg {
      radius: 5px;
    }
    font-size: 0.94rem;
    text-shadow: none;
    margin-right: 0.5rem;
    font-weight: 600;

    &_primary {
        background: $btn-primary-bg;
        color: $btn-primary-text-color;

        &:hover {
          background: $btn-primary-bg-hover;
          border-color: $btn-primary-bg-hover;
        }
    }

    &_secondary {
        background: $btn-secondary-bg;
        color: $btn-secondary-text-color;

        &:hover {
          background: $btn-secondary-bg-hover;
          color: $btn-secondary-bg;
        }
    }

    &_danger {
        background: $btn-danger-bg;
        color: $btn-danger-text-color;
        border: 1px solid $btn-danger-bg;

        &:hover {
          background: $btn-danger-bg-hover;
        }
    }

    &_disabled {
        background: $btn-disabled-bg;
        color: $btn-disabled-text-color;
        border: 1px solid $btn-disabled-bg;
        pointer-events:none;
    }

}

div.formButtonGroup input[type="submit"].btn {
  // yuck, but gotta beat the specificity of a primefaces rule
  &_full-width {
    width: 100%;
  }
  &_3-in-a-dialog {
    width: 31%;

    &.second-btn {
      margin-left: 12px;
    }
  }
}
